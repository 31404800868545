import React from 'react';
import Layout from '../../components/common/Layout';
import SignUp from '../../components/from/SignUp';
import SEO from '../../components/SEO';

const SignUpPage = () => (
  <Layout lang="en">
    <SEO
      title="Try Portknox free for 14 days - Nextcloud Hosting"
      description="Explore Nextcloud and Portknox.net for free"
    />
    <SignUp />
  </Layout>
);

export default SignUpPage;
